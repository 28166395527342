<template>
<div class="flex_column">
    <el-form label-position="left" label-width="80px">
        <!-- 课程名 -->
        <el-form-item label="课程名">
            <el-input v-model.trim="course_name"
            placeholder="请输入课程名称"></el-input>
        </el-form-item>
        <!-- 题库名 -->
        <el-form-item label="题库名">
            <el-input v-model.trim="store_name"
            placeholder="请输入题库名称"></el-input>
        </el-form-item>
        <!-- 专业部选择 -->
        <el-form-item label="专业部">
            <el-radio-group v-model="department" size="medium">
                <el-radio border label="信息部"></el-radio>
                <el-radio border label="商贸部"></el-radio>
                <el-radio border label="财经部"></el-radio>
            </el-radio-group>
        </el-form-item>
    </el-form>
    <!-- 提交按钮 -->
    <el-button type="primary" plain @click="submit">提交</el-button>
</div>
</template>
    
<script>
import axios from 'axios'
export default {
    name:'StoreAdd',
    props:['getStoreInfo','handleClose'],
    data() {
        return { 
            course_name: '',            //课程名
            store_name:'',              //题库名
            department:'信息部',         //专业部      
        }
    },
    methods: {
        // 提交按钮
        submit(event){
            // 前端表单校验
            if(!this.course_name){
                this.$message({
                    type:'warning',
                    message:'前端:课程名没填'
                })
                event.preventDefault()
                return false
            }
            if(!this.store_name){
                this.$message({
                    type:'warning',
                    message:'前端:题库名没填'
                })
                event.preventDefault()
                return false
            }
            // 发送请求
            let params = {
                course_name: this.course_name,
                store_name: this.store_name,
                department: this.department
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Exam_store_add.php',
                params:params
            }).then(
                response => {
                    console.log(response)
                    if(response.data.error == 0){
                        this.getStoreInfo()  // 再次请求,更新新数据
                        this.handleClose()   // 关闭弹出层
                        this.$message({
                            type:'success',
                            message:response.data.msg
                        })
                    }else{
                        this.$message.error(response.data.msg)
                    }
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        }
    }
}
</script>

<style scoped>
.input_box{
    width: 50%;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
}
</style>