<!-- Git上传步骤：
1 git init
2 git remote add origin xxxx
3 git pull origin master
4 git add .
5 git commit -m 说明
5 git remote add origin https://gitee.com/pevel/mysterious-dark-web-2.0.git
6 git push -u origin "master"
-->
<template>
<div id="app">
    <div id="percentageCounter" style="width: 6%;"></div>  <!-- 顶部彩色进度条 -->
    <Nav></Nav>
    <router-view></router-view>
    <Footer></Footer>
</div>
</template>

<script>
import axios from 'axios'
import Nav from './components/Nav.vue'
import Footer from './components/Footer.vue'
// 集成第三方动画(https://animate.style/)
import 'animate.css'

export default {
	name: 'App',
	components: {Nav,Footer},
	methods:{
		// 挂载live2D看板娘
		mount_live2d(){
            // 初音、猫、狗
			var live2dArr1 = ['live2d-widget-model-miku','live2d-widget-model-hijiki','live2d-widget-model-wanko']
			var live2dArr2 = ['miku','hijiki','wanko']
			var random_number=Math.round(Math.random()*2)
			setTimeout(() => {
				window.L2Dwidget.init({
					pluginRootPath: 'live2dw/',
					pluginJsPath: 'lib/',
					pluginModelPath: live2dArr1[random_number]+'/assets/',
					tagMode: false,
					debug: false,
					model: { jsonPath: '../live2dw/'+live2dArr1[random_number]+'/assets/'+live2dArr2[random_number]+'.model.json' },
					display: { position: 'left', width: 200, height: 400 },
					mobile: { show: true },
					log: false
				})
			}, 1000)
		},
		// 获取到后端的缓存信息并保存到vuex
		get_session(){
			axios.get('https://www.xiaolily.cn/Session_get.php').then(
				response => {
                    // console.log('vuex获取缓存信息',response)
					this.$store.commit('SESSION',
                        [response.data.username,
                        response.data.user_id,
                        response.data.class_num,
                        response.data.admin,
                        response.data.admin_name,
                        response.data.avatar,
                        response.data.class_name]
                    )
				},
				error => {
					console.log('请求失败了',error.message)
				}
			)
		}
	},
	mounted() {
		this.mount_live2d()
        this.get_session()
	},
}
</script>

<style>
/* 全局样式 */
*{
  margin:0;
  padding: 0;
  /* 禁止页面元素被选中和复制 */
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
a{
  /* 去掉文本装饰线 */
  text-decoration: none;
  color: black;
}
body{
    /* background-image:url("./assets/image/bg3.jpg"); */
    cursor: url("./assets/image/left_ptr.png"),auto;
    width: 100vw;
    overflow-x: hidden;
    background-color: #eaeaea;
}

/* 排列 */
.flex_row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.flex_column{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* 文字 */
h1{
    text-align: center;
    width: 100%;
}
h3{
    font-size: 24px;
    color: #909399;
}
h4{
    font-size: 18px;
    color: #909399;
}

/* 图片 */
.icon_img{
    width: 25px;
}
.small_img{
    width: 40px;
    height: 40px;
}
.medium_img{
    width: 60px;
}
.big_img{
    width: 80px;
}

/* 颜色 */
.red{
    color: red;
}
.grey{
    color: gray;
}

/* 修改elment的按钮的字体加粗 */
button{
    font-weight: bolder !important;
}

/* 站酷快乐体！*/
@font-face {
    font-family: "zhanku";
    src: url("./assets/woff/zhanku.woff"); /* IE9 */
    font-style: normal;
    font-weight: normal;
}
.chinese_font {
    font-family: 'zhanku', 'Merriweather Sans', Helvetica, Tahoma, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft Yahei', 'WenQuanYi Micro Hei', sans-serif
}

/* 鼠标样式 */
label,button,i,textarea,input,#security_code_image,.button-cursor,a:hover {
    cursor: url("./assets/image/No_Disponible.cur"), pointer !important;
}

/* 白猫回到顶部 */
.cd-top.cd-is-visible {
    opacity: 1;
    top: -326px
}
.cd-top.cd-fade-out {
    opacity: 1
}
.cd-top:hover {
    opacity: 1
}
.cd-top span {
    display: none;
    color: #000;
    position: absolute;
    bottom: 0;
    height: 20px;
    width: 50px;
    text-align: center
}
.cd-top {
    position: fixed;
    right: 25px;
    top: -900px;
    z-index: 99;
    width: 70px;
    height: 900px;
    background: url(./assets/image/scroll.png) no-repeat center;
    background-size: contain;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    opacity: 1
}

/*顶部彩色进度条*/
#percentageCounter{
position:fixed; 
left:0; 
top:0; 
height:3px; 
z-index:99999;
background-image: linear-gradient(to right, #339933,#FF6666);
border-radius:5px;
}

/* 滚动条美化 */
::-webkit-scrollbar-thumb {
    background-color: #FF2A68;
    background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.4) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.4) 50%,rgba(255,255,255,.4) 75%,transparent 75%,transparent);
    border-radius: 3em;
}
::-webkit-scrollbar-track {
    background-color: #ffcacaff;
    border-radius: 3em;
}
::-webkit-scrollbar {
    width: 8px;
    height: 15px;
}

/* css动画（旋转） */
@keyframes rotating {
    from {
        -ms-transform: rotate(0);
        -moz-transform: rotate(0);
        -webkit-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}
.rotating {
    -webkit-animation: rotating 3s linear infinite;
    -moz-animation: rotating 3s linear infinite;
    -ms-animation: rotating 3s linear infinite;
    -o-animation: rotating 3s linear infinite;
    animation: rotating 3s linear infinite
}
</style>
