<template>
<div>
<!-- banner图 -->
<!-- <img src="../assets/image/lucky.jpg" id="banner"> -->
<!-- 转盘区域 -->
<div class="section chinese-font">
    <!-- 选择班级以及抽取幸运儿 -->
    <div class="choose_class" >
        <div class="flex_row">
            <div>
                <el-select v-model="choose_class" placeholder="请选择班级">
                    <el-option
                    v-for="item in class_list"
                    :key="item.class_name"
                    :label="item.class_name"
                    :value="item.class_name">
                    </el-option>
                </el-select>
            </div>
            <el-button 
                style="margin: 0 20px;" 
                type="success" plain 
                @click="select">
                抽取幸运儿
            </el-button>
            <!-- 加分(操作权限2) -->
            <el-button style="margin: 0 20px;" type="primary" plain
            @click="addJifen()"
            v-if="$store.state.admin>=2">加分
            </el-button>
        </div>  
    </div>
    <!-- 大转盘 -->
    <div class="flex_row" style="width: 100%;justify-content: space-evenly;">
        <LuckyWheel
            ref="myLucky"
            width="300px"
            height="300px"
            :prizes="prizes"
            :blocks="blocks"
            :buttons="buttons"
            @start="startCallback"
            @end="endCallback"
        />
    </div>
    <!-- 奖励 -->
    <div class="flex_row" style="width: 100%;justify-content: space-evenly;">
        <!-- 小转盘 -->
        <LuckyWheel
            ref="zhuanPan"
            width="270px"
            height="270px"
            :prizes="prizes_small"
            :blocks="blocks"
            :buttons="buttons"
            @start="startZP"
        />
        <!-- 老虎机 -->
        <div class="flex_column" style="align-items: flex-end;">
            <!-- <div class="flex_row" style="justify-content: space-between;">
                <div class="flex_column">
                    <h2>转转乐</h2>
                    <h4>拼一拼、搏一搏、单车变摩托</h4>
                    <h4 style="color: red;">更有三万大奖等你来拿</h4>
                </div>
            </div> -->
            <!-- 老虎机开始按钮 -->
            <img @click="start_tiger()" 
            src="../assets/image/tiger.png" style="width: 50px;" alt="">
            <SlotMachine 
                ref="tiger"
                width="300px"
                height="200px"
                :prizes="prizes_tiger"
                :blocks="blocks_tiger"
                :slots="slots"
                :defaultStyle="defaultStyle"
                :defaultConfig="defaultConfig"
            />
        </div>
        <!-- 九宫格 -->
        <LuckyGrid
            ref="LuckyGrid"
            width="300px"
            height="300px"
            :blocks="blocks"
            :prizes="prizes_grid"
            :buttons="buttons_grid"
            @start="start_grid"
        />
    </div>
</div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    name:'MyLucky',
    data () {
        return {
            class_list:[],
            choose_class:'',
            username:'',   // 抽中的学生
            user_id:'',
            // 九宫格
            prizes_grid: [
                {
                    x: 0,
                    y: 0,
                    fonts: [{ text: "谢谢参与", top: "60%" }],
                    imgs: [
                        {
                        src: require('../assets/image/得意.png'),
                        width: "45%",
                        top: "15%",
                        },
                    ],
                    background: "#b3c8fa",
                },
                {
                    x: 1,
                    y: 0,
                    fonts: [{ text: "三千积分", top: "60%" }],
                    imgs: [
                        {
                        src: require('../assets/image/gift.png'),
                        width: "45%",
                        top: "15%",
                        },
                    ],
                    background: "#ff2219",
                },
                {
                    x: 2,
                    y: 0,
                    fonts: [{ text: "谢谢参与", top: "60%" }],
                    imgs: [
                        {
                        src: require('../assets/image/得意.png'),
                        width: "45%",
                        top: "15%",
                        },
                    ],
                    background: "#b3c8fa",
                },
                {
                    x: 2,
                    y: 1,
                    fonts: [{ text: "全班一百", top: "60%" }],
                    imgs: [
                        {
                        src: require('../assets/image/gift.png'),
                        width: "45%",
                        top: "15%",
                        },
                    ],
                    background: "#b3c8fa",
                },
                {
                    x: 2,
                    y: 2,
                    fonts: [{ text: "谢谢参与", top: "60%" }],
                    imgs: [
                        {
                        src: require('../assets/image/得意.png'),
                        width: "45%",
                        top: "15%",
                        },
                    ],
                    background: "#b3c8fa",
                },
                {
                    x: 1,
                    y: 2,
                    fonts: [{ text: "全班一百", top: "60%" }],
                    imgs: [
                        {
                        src: require('../assets/image/gift.png'),
                        width: "45%",
                        top: "15%",
                        },
                    ],
                    background: "#b3c8fa",
                },
                {
                    x: 0,
                    y: 2,
                    fonts: [{ text: "谢谢参与", top: "60%" }],
                    imgs: [
                        {
                        src: require('../assets/image/得意.png'),
                        width: "45%",
                        top: "15%",
                        },
                    ],
                    background: "#b3c8fa",
                },
                {
                    x: 0,
                    y: 1,
                    fonts: [{ text: "全班一百", top: "60%" }],
                    imgs: [
                        {
                        src: require('../assets/image/gift.png'),
                        width: "45%",
                        top: "15%",
                        },
                    ],
                    background: "#b3c8fa",
                },
            ],
            buttons_grid: [
                {
                    x: 1,
                    y: 1,
                    background: "#9c9dd8",
                    fonts: [{ text: "抽奖", top: "40%" }],
                },
            ],
            // 转盘
            blocks: [
                { padding: '10px', background: '#ffc27a' },
                { padding: '10px', background: '#ff4a4c' },
                { padding: '0px', background: '#fff' }          
            ],
            prizes: [
                { background: '#f8d384', fonts: [{ text: '0' }],imgs: [{src:require('../assets/image/niName.png'),width:50,top:20}] },
                { background: '#f9e3bb', fonts: [{ text: '1' }],imgs: [{src:require('../assets/image/niName.png'),width:50,top:20}] },
                { background: '#f8d384', fonts: [{ text: '2' }],imgs: [{src:require('../assets/image/niName.png'),width:50,top:20}] },
                { background: '#f9e3bb', fonts: [{ text: '3' }],imgs: [{src:require('../assets/image/niName.png'),width:50,top:20}] },
                { background: '#f8d384', fonts: [{ text: '4' }],imgs: [{src:require('../assets/image/niName.png'),width:50,top:20}] },
                { background: '#f9e3bb', fonts: [{ text: '5' }],imgs: [{src:require('../assets/image/niName.png'),width:50,top:20}] },
            ],
            buttons: [
                { radius: '40px', background: '#d64737' },
                { radius: '35px', background: '#f6c66f', pointer: true },
                {
                radius: '30px',
                background: '#fff',
                fonts: [{ text: '开始', top: '-13px' }]
                }
            ],
            // 奖励转盘
            prizes_small: [
                { background: '#f8d384', fonts: [{ text: '全班一百',top:5 }], range:1 },
                { background: '#f9e3bb', fonts: [{ text: '三百积分',top:5 }], range:1 },
                { background: '#f8d384', fonts: [{ text: '全班两百',top:5 }], range:1 },
                { background: '#f9e3bb', fonts: [{ text: '两百积分',top:5 }], range:1 },
                { background: '#f8d384', fonts: [{ text: '全班五十',top:5 }], range:1 },
                { background: '#f9e3bb', fonts: [{ text: '五百积分',top:5 }], range:1 },
            ],
            // 老虎机
            slots: [
                { order: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], direction: 1,speed:3 },
                { order: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0], direction: -1,speed:6 },
                { order: [2, 3, 4, 5, 6, 7, 8, 9, 0, 1], direction: 1,speed:9 },
            ],
            blocks_tiger: [
                { padding: '10px', background: '#869cfa' },
                { padding: '10px', background: '#e9e8fe' },
            ],
            prizes_tiger: [
                { 
                    background: '#ff0000',
                    borderRadius: '10px',
                    fonts: [{ text: '一万积分', top: '35%',fontSize:'18px' }] 
                },
                { 
                    background: '#6699ff',
                    borderRadius: '10px',
                    fonts: [{ text: '三千积分', top: '35%',fontSize:'18px' }] 
                },
                { 
                    background: '#00cc00',
                    borderRadius: '10px',
                    fonts: [{ text: '全班一千', top: '35%',fontSize:'18px' }] 
                },
                { 
                    background: '#9933ff',
                    borderRadius: '10px',
                    fonts: [{ text: '全班五百', top: '35%',fontSize:'18px' }] 
                },
                { 
                    background: '#ffb7c4',
                    borderRadius: '10px',
                    fonts: [{ text: '五千积分', top: '35%',fontSize:'18px' }] 
                },
                { 
                    background: '#6699ff',
                    borderRadius: '10px',
                    fonts: [{ text: '三千积分', top: '35%',fontSize:'18px' }] 
                },
                { 
                    background: '#00cc00',
                    borderRadius: '10px',
                    fonts: [{ text: '全班一千', top: '35%',fontSize:'18px' }] 
                },
                { 
                    background: '#9933ff',
                    borderRadius: '10px',
                    fonts: [{ text: '全班五百', top: '35%',fontSize:'18px' }] 
                },
                { 
                    background: '#9933ff',
                    borderRadius: '10px',
                    fonts: [{ text: '三千积分', top: '35%',fontSize:'18px' }] 
                },
                { 
                    background: '#ff9900',
                    borderRadius: '10px',
                    fonts: [{ text: '全班五百', top: '35%',fontSize:'18px' }] 
                },
            ],
            defaultStyle: {
                borderRadius: Infinity,
                background: '#bac5ee',
                fontSize: '32px',
                fontColor: '#333'
            },
            defaultConfig: {
                rowSpacing: '20px',
                colSpacing: '10px',
                // decelerationTime: 5000
            },
        }
    },
    methods: {
        // 获取班级列表
        ClassListGet(){
            // 发送请求
            let params = {
                currentPage: 1,
                page_size: 100,
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Class_get.php',
                params:params
            }).then(
                response => {
                    // console.log(response)
                    this.class_list = response.data.data
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 抽取幸运儿
        select(){
            // 如果没有选择班级
            if(!this.choose_class){
                this.$message({
                    type:'warning',
                    message:'前端:请先选择班级'
                })
                return
            }
            // 获取学生列表数据、并从中随机抽取学生、替换转盘数据
            axios.get('https://www.xiaolily.cn/Users_class_get.php?class_name='+this.choose_class).then(
                response => {
                    // console.log(response)
                    // 循环转盘数据
                    for(var i=0;i<this.prizes.length;i++){
                        // 产生随机数（随机数的最大值是班级人数,因为用这种方法取的范围会多1，减一的话又会出现负值，所以再取绝对值）
                        var random_number = Math.abs(Math.round(Math.random()*response.data.data.length)-1)
                        this.prizes[i].fonts[0].text = response.data.data[random_number].username
                        this.prizes[i].fonts[0].id = response.data.data[random_number].id
                        this.prizes[i].imgs[0].src ='https://www.xiaolily.cn/' + response.data.data[random_number].avatar
                    }
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 点击抽奖按钮会触发star回调
        startCallback () {
            // 调用抽奖组件的play方法开始游戏
            this.$refs.myLucky.play()
            // 模拟调用接口异步抽奖
            setTimeout(() => {
                // 假设后端返回的中奖索引是0
                const index = Math.floor(Math.random() * 6)
                // 调用stop停止旋转并传递中奖索引
                this.$refs.myLucky.stop(index)
            }, 3000)
        },
        // 奖励转盘
        startZP () {
            if(this.$store.state.admin<2){
                this.$message({
                    type:'warning',
                    message:'无操作权限'
                })
                return false
            }
            if(!this.username){
                this.$message({
                    type:'warning',
                    message:'前端:请先抽出一名同学'
                })
                return false
            }
            // 调用抽奖组件的play方法开始游戏
            this.$refs.zhuanPan.play()
            // 模拟调用接口异步抽奖
            setTimeout(() => {
                // 假设后端返回的中奖索引是0
                const index = Math.floor(Math.random() * 5)
                // 调用stop停止旋转并传递中奖索引
                // 如果再配置中设置了中奖概率，就不要传递索引了，索引具有高优先级
                this.$refs.zhuanPan.stop(index)
                console.log(index)
                let jifen = 0
                let all = 0
                switch (index) {  
                    case 0: // 全班一百
                        jifen = 100
                        all = 1
                        break  
                    case 1: // 三百积分
                        jifen = 300
                        all = 0
                        break  
                    case 2:  // 全班两百
                        jifen = 200
                        all = 1
                        break  
                    case 3:  // 两百积分
                        jifen = 200
                        all = 0
                        break
                    case 4:  // 全班五十
                        jifen = 50
                        all = 1
                        break
                    case 5:  // 五百积分
                        jifen = 500
                        all = 0
                        break  
                    default:
                        break
                }
                // 中奖提示
                setTimeout(() => {
                    if(jifen != 0){
                        alert(`恭喜获得${jifen}积分大奖`)
                    }else{
                        alert('很遗憾未获奖')
                    }
                }, 3500)
                // 发送请求
                this.addJifen_request(jifen,all)
            }, 3000)
        },
        // 发送增加积分的请求
        addJifen_request(jifen,all){
            // 发送请求
            let params = {
                jifen: jifen,
                all:all,
                username: this.username,
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Jifen_add.php',
                params:params
            }).then(
                response => {
                    console.log(response)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 抽奖结束会触发end回调，找到中奖学生id
        endCallback (prize) {
            this.user_id = prize.fonts[0].id
            this.username = prize.fonts[0].text
        },
        // 增加50积分和经验
        addJifen() {
            if(this.$store.state.admin<2){
                this.$message({
                    type:'warning',
                    message:'无操作权限'
                })
                return false
            }
            if(!this.user_id){
                this.$message.error('还没有抽取');
                return false
            }
            let params = {
                id: this.user_id,
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/UserAdmin_mod_jifen.php',
                params:params
            }).then(
                response => {
                    // console.log('增加积分',response)
                    this.$message({
                        message: '给'+this.username+'增加50积分成功',
                        type: 'success',
                        duration:2000
                    })
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 老虎机开始
        start_tiger(){
            if(this.$store.state.admin<2){
                this.$message({
                    type:'warning',
                    message:'无操作权限'
                })
                return false
            }
            if(!this.username){
                this.$message({
                    type:'warning',
                    message:'前端:请先抽出一名同学'
                })
                return false
            }
            this.$refs.tiger.play()
            setTimeout(() => {
                const index = [Math.floor(Math.random()*5) ,Math.floor(Math.random()*5) ,Math.floor(Math.random()*5) ]
                // 调用 stop 方法停止游戏
                this.$refs.tiger.stop(index)
                // 如果数组中数字不一样则中断
                for (let i = 1; i < index.length; i++){
                    if (index[0] !== index[i]) {  
                        return false;  
                    }  
                }
                let jifen = 0
                let all = 0  
                // 中奖提示
                setTimeout(() => {
                    if(jifen != 0){
                        alert(`恭喜获得${jifen}积分大奖`)
                    }else{
                        alert('很遗憾未获奖')
                    }
                }, 5000)
                switch (index[0]) {  
                    case 0: // 一万积分*3
                        jifen = 30000
                        all = 0
                        break  
                    case 1: // 三千积分*3
                        jifen = 9000
                        all = 0
                        break  
                    case 2:  // 全班一千*3
                        jifen = 3000
                        all = 1
                        break  
                    case 3:  // 全班五百*3
                        jifen = 1500
                        all = 1
                        break  
                    case 4:  // 五千积分*3
                        jifen = 15000
                        all = 0
                        break  
                    default:
                        console.log('没有符合条件的')
                }
                // 发送请求
                this.addJifen_request(jifen,all)
            }, 500);
        },
        // 九宫格开始
        start_grid() {
            if(this.$store.state.admin<2){
                this.$message({
                    type:'warning',
                    message:'无操作权限'
                })
                return false
            }
            // 调用抽奖组件的play方法开始游戏
            this.$refs.LuckyGrid.play();
            // 模拟调用接口异步抽奖
            setTimeout(() => {
                const index = Math.floor(Math.random()*8);
                let jifen = 0
                let all = 0
                switch (index) {  
                    case 0: // 谢谢参与
                        break  
                    case 1: // 两千积分
                        jifen = 3000
                        all = 0
                        break  
                    case 2:  // 谢谢参与
                        break  
                    case 3:  // 全班一百
                        jifen = 100
                        all = 1
                        break  
                    case 4:  // 谢谢参与
                        break  
                    case 5:  // 全班一百
                        jifen = 100
                        all = 1
                        break  
                    case 6:  // 谢谢参与
                        break  
                    case 7:  // 全班一百
                        jifen = 100
                        all = 1
                        break  
                    default:
                        console.log('没有符合条件的')
                }
                // 中奖提示
                setTimeout(() => {
                    if(jifen != 0){
                        alert(`恭喜获得${jifen}积分大奖`)
                    }else{
                        alert('很遗憾未获奖')
                    }
                }, 4000)
                // 发送请求
                this.addJifen_request(jifen,all)
                // 调用stop停止旋转并传递中奖索引
                this.$refs.LuckyGrid.stop(index);
            }, 3000);
        },
    },
    mounted(){
        this.ClassListGet()
    }
}
</script>

<style scoped>
.choose_class{
    margin-bottom: 10px;
}
#banner{
    width: 70vw;
    margin-left: 15vw;
}

.section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    opacity: 0.8;
    border-radius: 12px;
    margin-top: 10vh;
    width: 65vw;
    margin-left: 15vw;
    padding: 20px;
}
</style>