<template>
<div class="flex_column section">

    <el-select v-model="choosed_class" filterable placeholder="请选择班级">
        <el-option
        v-for="item in class_list"
        :key="item.id"
        :label="item.class_name"
        :value="item.class_num">
        </el-option>
    </el-select>

    <ScoreRank 
    :class_num="choosed_class"
    post_url="https://www.xiaolily.cn/Echarts_exam.php"
    title='积分'
    field="jifen">
    </ScoreRank>

    <ScoreRank 
    :class_num="choosed_class"
    post_url="https://www.xiaolily.cn/Echarts_exam.php"
    title='经验'
    field="ex">
    </ScoreRank>

    <ScoreRank 
    :class_num="choosed_class"
    post_url="https://www.xiaolily.cn/Echarts_exam.php"
    title='考试平均分'
    field="average_score">
    </ScoreRank>

    <ScoreRank 
    :class_num="choosed_class"
    post_url="https://www.xiaolily.cn/Echarts_exam.php"
    title='作业绩点'
    field="task_scope">
    </ScoreRank>
</div>
</template>

<script>
import ScoreRank from '../../components/ScoreRank'
import axios from 'axios'
export default {
    name:"MyEcharts",
    components:{ScoreRank},
    data(){
        return {
            class_list:[{},{}],   // 班级列表，用于下拉框选择班级
            choosed_class:''      // 用户通过下拉选择框选中的班级
        }
    },
    methods:{
        // 获取班级列表
        getClassList(){
            // 发送数据
            let params = {
                currentPage: 1,
                page_size: 100  
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Class_get.php',
                params:params
            }).then(
                response => {
                    this.class_list = response.data.data
                    // console.log(this.class_list)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        }
    },
    mounted(){
        this.getClassList()
    }
}
</script>

<style>
.section{
    width: 80vw;
    margin: 3vh 5vw 3vh 15vw;
}
</style>