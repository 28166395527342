<template>
<div class="flex_column">
    <!-- 动图 -->
    <div class="flex_column" style="margin-bottom: 15px;">
        <img class="big_img" src="../assets/image/iloli.gif">
        <p class="hint_font">Hi! 请注册</p>
    </div>
    <!-- 用户名可用提示 -->
    <el-alert v-show="username_hint" :closable="false"
    :title="username_hint"
    :type="username_hint_type">
    </el-alert>
    <!-- label-position: 'right'，对齐方式，还有left和top -->
    <el-form label-position="left" label-width="80px">
        <el-form-item label="用户名">
            <el-input v-model.trim="username" @blur="CheckUsername()"
            placeholder="真实姓名，只能是中文汉字"></el-input>
        </el-form-item>
        <el-form-item label="密码">
            <el-input v-model.trim="password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
            <el-input v-model.trim="cpassword" type="password"></el-input>
        </el-form-item>
        <el-form-item label="班级码">
            <el-input v-model.trim="class_num"></el-input>
        </el-form-item>
    </el-form>
    <el-button type="primary" plain @click="submit">注册</el-button>
</div>
</template>

<script>
import axios from 'axios'
export default {
    name:'MySignup',
    props:["handleClose"],
    data(){
        return{
            username:'',              // 用户名
            password:'',              // 密码
            cpassword:'',             // 确认密码
            class_num:'',             // 班级码
            username_hint:'',         // 用户名可用提示
            username_hint_type:''     // 用户名可用提示的类型
        }
    },
    methods:{
        // Ajax异步验证用户名是否可用
        CheckUsername(){
            // 前端校验
            let usernameReg = /^[\u4E00-\u9FA5]{2,10}$/
            if(!usernameReg.test(this.username)){
                this.username_hint = '姓名只能输入中文汉字,2-10个'
                this.username_hint_type = 'warning'
                return
            }
            // 后端校验
            let params = {
                username: this.username,
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Username_check.php',
                params:params
            }).then(
                response => {
                    // 用户名空校验
                    if(response.data.error == 1){
                        this.username_hint = response.data.msg
                        this.username_hint_type = 'warning'
						return
					}
                    // 检查用户名是否可用
					if(response.data.state == 0){
                        this.username_hint = response.data.msg
                        this.username_hint_type = 'success'
					}else{
                        this.username_hint = response.data.msg
                        this.username_hint_type = 'warning'
					}
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 注册按钮
        submit(event){
            //前端校验合法性校验
            let usernameReg = /^[\u4E00-\u9FA5]{2,10}$/
            if(!usernameReg.test(this.username)){
                this.$message({
                    type:'warning',
                    message:'前端:姓名只能输入中文汉字,2-10个'
                })
                event.preventDefault()
                return false
            }
            let pwReg = /^[a-zA-Z0-9_*]{6,18}$/
            if(!pwReg.test(this.password)){
                this.$message({
                    type:'warning',
                    message:'前端:密码只能输入中英文和数字,以及_*,6-18位'
                })
                event.preventDefault()
                return false
            }
            if(this.cpassword!=this.password){
                this.$message({
                    type:'warning',
                    message:'前端:两次输入密码不一致'
                })
                event.preventDefault()
                return false
            }
            let class_numReg = /^[0-9]{4}$/
            if(!class_numReg.test(this.class_num)){
                this.$message({
                    type:'warning',
                    message:'前端:班级码只能输入4位数字'
                })
                event.preventDefault()
                return false
            }
            // 发送数据
            let params = {
                username: this.username,
                password: this.password,
                cpassword: this.cpassword,
                class_num:this.class_num
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Signup_add.php',
                params:params
            }).then(
                response => {
                    if (response.data.error == 0){
                        this.$message({
                            message: '注册成功',
                            type: 'success'
                        })
                        this.handleClose()  //关闭注册窗口
                    }
                    else{
                        this.$message.error(response.data.msg)
                    }
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        }
    }
}
</script>
 
<style scoped>
.hint_font{
    font-family: Ubuntu, sans-serif;
    font-size: 16px;
    font-weight: bolder;
    color: rgb(134, 134, 134);
}
</style>