<template>
<body>
    <div class="table_section">
        <!-- stripe属性创建带斑马纹的表格，border带边框,height='70vh'固定表头 -->
        <el-table
            border
            stripe 
            height='70vh'
            :data="history_list"
            :default-sort = "{prop: 'date', order: 'descending'}"
        >
        <el-table-column
            prop="id"
            label="序号"
            sortable
            width="80"
            align="center">
        </el-table-column>
        <el-table-column
            prop="avatar_name"
            label="商品名"
            sortable
            width="200"
            align="center">
        </el-table-column>
        <el-table-column
            prop="seller"
            label="卖家"
            sortable
            width="160"
            align="center">
        </el-table-column>
        <el-table-column
            prop="buyer"
            label="买家"
            sortable
            width="160"
            align="center">
        </el-table-column>
        <el-table-column
            prop="price"
            label="商品价格"
            sortable
            width="120"
            align="center">
        </el-table-column>
        <el-table-column
            prop="earning"
            label="收入"
            sortable
            width="120"
            align="center">
        </el-table-column>
        <el-table-column
            prop="charge"
            label="手续费"
            sortable
            width="120"
            align="center">
        </el-table-column>
        <el-table-column
            prop="time"
            label="购买时间"
            sortable
            width="200"
            align="center">
        </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="margin:2vh 0">
            <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[25, 50, 75, 100]"
            :page-size="50"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
    </div>
  </body>
</template>
    
<script>   
import axios from 'axios'
export default {
    name:"TaskAdmin",
    data() {
        return {
            currentPage: 1,          // 当前的页码
            page_size: 50,           // 每页显示条数
            history_list:[],      
            total:1,                 // 用户列表总长度
        }
    },
    methods: {
        // 改变每页显示数量事件
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.page_size = val
            this.getData()
        },
        // 页码切换事件
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.getData()
        },
        // 分页获取数据
        getData(){
            // 发送数据
            let params = {
                currentPage: this.currentPage,
                page_size: this.page_size,
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Shop_history_get.php',
                params:params
            }).then(
                response => {
                  this.history_list = response.data.data
                  this.total = parseInt(response.data.total)
                  // console.log(this.history_list)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        }
    },
    mounted(){
        this.getData()
    }
}
</script>

<style scoped>
body{
    width: 80vw;
    margin-left: 12vw;
    margin-top: 2vh;
    min-height: 80vh;
    background-color: rgba(255, 255, 255, 0.5);
}
</style>