<template>
    <body class="chinese_font">
        <div class="flex_row" style="padding: 10px;width: 100%;justify-content: space-between;">
            <el-button @click="addStore()"
            type="primary" plain>发布商品</el-button>
            <div class="flex_column">
                <div style="margin-right: 50px;">规则: 发布商品需300积分上架费,卖出后总的收益还要扣除20%手续费</div>
                <span style="color: red;margin-right: 50px;">注意：包含恐怖、暴力、色情等元素，或者有侮辱、脏话等，审核不过，并封号处理</span>
            </div>
        </div>
        <div class="flex_row" style="justify-content: flex-start;">
            <div v-for="(i,index) in data_list" :key="index"
            class="avatar_box flex_column" style="justify-content: flex-start;align-items: flex-start;">
                <!-- 头像 -->
                <div style="width: 100%;height: 7vw;" class="flex_row">
                    <div v-if="$store.state.admin>=1">
                        <img style="width: 6vw;height: 6vw;border-radius: 10px;"
                        :src="'https://www.xiaolily.cn/'+i.file_dir+'/'+i.file_name" alt="">
                    </div>
                    <div v-else>
                        <img style="width: 6vw;height: 6vw;border-radius: 10px;"
                        src="../../assets/image/audit.png" alt="">
                    </div>
                </div>
                <!-- 信息 -->
                <p class="avatar_title">{{i.avatar_name}}</p>
                <p class="avatar_des">{{i.avatar_des}}</p>
                <div class="avatar_info flex_column" style="align-items: flex-end;">
                    <p class="gray">作者：{{i.username}}</p>
                </div>
                <div class="price_and_buy flex_row">
                    <div class="flex_row">
                        <img width="20px" class="buy_icon"
                        src="../../assets/image/jifen.png" alt="">
                        <span>{{i.price}}</span>
                    </div>
                </div>
                <div class="flex_row" style="width: 100%;justify-content: space-around;">
                    <!-- 审核 -->
                    <div v-if="$store.state.admin>=1 && i.audit==0">
                        <el-button @click="audit(i.id)" type="primary" size="mini">审核</el-button>
                    </div>
                    <div v-if="$store.state.admin<1">
                        <el-button type="primary" size="mini" disabled>审核</el-button>
                    </div>
                    <!-- 删除 -->
                    <div v-if="$store.state.admin>=2">
                        <el-button 
                        @click="del(i.id,i.file_dir,i.file_name)"
                        type="danger" size="mini">删除</el-button>
                    </div>
                    <div v-else>
                        <el-button 
                        @click="del(i.id,i.file_dir,i.file_name)"
                        type="danger" size="mini" disabled>删除</el-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 分页 -->
        <div class="pagination">
            <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[6, 12, 18, 24]"
            :page-size="6"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
        <!-- 发布商品 -->
        <el-dialog
            title="发布商品"
            :visible.sync="addStoreVisible"
            width="50%"
            top=5vh
            :before-close="handleClose">
                <Store_add :handleClose="handleClose"
                :getDataList="getDataList"></Store_add>
        </el-dialog>
    </body>
</template>

<script>
import axios from 'axios'
import Store_add from './Store_add.vue'
export default {
    name:'ShopAudit',
    components:{Store_add}, 
    data() {
        return {
            currentPage: 1,       // 当前的页码
            page_size: 6,         // 每页显示条数
            total:1,              // 题库列表总长度
            addStoreVisible:false,// 新增库存弹出层
            data_list:[]
        }
    },
    methods: {
        // 审核
        audit(id){
            let params = {
                id: id,
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Shop_audit.php',
                params:params
            }).then(
                response => {
                    console.log(response)
                    if(response.data.error == 1){
                        this.$message({
                            message: response.data.msg,
                            type: 'warning'
                        })
                    }else{
                        // 数据库已经修改成功
                        this.$message({
                            message: response.data.msg,
                            type: 'success',
                            duration: 1500
                        })
                        this.getDataList()
                    }
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 删除商品
        del(id,file_dir,file_name){
            this.$MessageBox.confirm('此操作将永久删除该商品,是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const file_url = file_dir+'/'+file_name
                // 发送请求
                let params = {
                    dataSheet: 'shop',
                    id: id,
                    file_url: file_url,
                }
                axios({
                    method:'get',
                    url:'https://www.xiaolily.cn/Delete.php',
                    params:params
                }).then(
                    response => {
                    this.getDataList()
                    // 删除成功提示
                    this.$message({
                        type: 'success',
                        message: response.data.msg
                    });
                    },
                    error => {
                        console.log('请求失败了',error.message)
                    }
                )
            }).catch(() => {
                console.log('用户点击取消')
            });
        },
        // 新增商品
        addStore(){
            this.$MessageBox.confirm('中华人民共和国英雄烈士保护法规定：英雄烈士的姓名、肖像、名誉、荣誉受法律保护，禁止歪曲、丑化、亵渎、否定英雄烈士的事迹和精神，宣扬、美化侵略战争和侵略行为，将依法惩处直至追究刑责（会处以有期徒刑）。网络运营者发现侵害英雄烈士名誉荣誉的网络信息时，负有及时处置的义务(所以我这边的数据库都记录了IP地址和发布时间，一经发现立即调监控找出始作俑者！！！)', '特别提示', {
                confirmButtonText: '我已知晓',
                cancelButtonText: '算了，不发了',
                type: 'warning'
            }).then(() => {
                this.addStoreVisible = true 
            }).catch(() => {
        
            });
        },
        // 关闭弹出框
        handleClose() {
            this.addStoreVisible = false
        },
        // 分页获取数据
        getDataList(){
            // 发送数据
            let params = {
                currentPage: this.currentPage,
                page_size: this.page_size,
                audit:0
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Shop_get.php',
                params:params
            }).then(
                response => {
                    this.data_list = response.data.data
                    this.total = parseInt(response.data.total)
                    // console.log(response)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 改变每页显示数量事件
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.page_size = val
            this.getDataList()
        },
        // 页码切换事件
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.getDataList()
        },
    },
    mounted(){
        this.getDataList()
    }
}
</script>

<style scoped>
.pagination{
    position: absolute;
    bottom: 5vh;
    left: 20vw;
}
body{
    width: 80vw;
    margin-left: 15vw;
    margin-top: 3vh;
    min-height: 80vh;
    background-color: rgba(255, 255, 255, 0.5);
}
.buy_icon:hover{
    background-image: none;
}
.avatar_info{
    width: 100%;
}
.gray{
    color: gray;
    font-size: small;
}
.price_and_buy{
    width: 100%;
    margin: 10px 0;
    justify-content: space-between;
}
.avatar_des{
    font-size: small;
}
.avatar_title{
    margin: 5px 0;
}
.avatar_box{
    width: 13%;
    height: 35vh;
    box-sizing: border-box;
    padding: 10px;
    margin: 20px;
    background-color: white;
    border-radius: 15px;
}
</style>