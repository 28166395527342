<template>
<div>
    <!-- 表格+页码 -->
    <div class="flex_column">
    <!-- 表格 -->
    <div class="table_section">
        <!-- stripe属性创建带斑马纹的表格，border带边框,height='70vh'固定表头 -->
        <el-table
            border
            stripe 
            height='70vh'
            :data="store_list"
            :default-sort = "{prop: 'date', order: 'descending'}"
        >
        <el-table-column
            prop="id"
            label="序号"
            sortable
            width="80"
            align="center">
        </el-table-column>
        <el-table-column
            prop="course_name"
            label="课程名"
            sortable
            width="200">
        </el-table-column>
        <el-table-column
            prop="store_name"
            label="题库名"
            width="200"
            sortable>
        </el-table-column>
        <el-table-column
            prop="publisher"
            label="发布者"
            width="100"
            sortable
            align="center">
        </el-table-column>
        <el-table-column
            prop="department"
            label="部门"
            sortable
            width="100"
            align="center">
        </el-table-column>
        <el-table-column label="操作">
            <template slot="header" slot-scope="scope">
                <el-button v-if="$store.state.admin>=2"
                type="primary" size="small"
                @click="addStore(scope)">新增题库</el-button>
                <el-button v-else
                disabled
                type="primary" size="small"
                @click="addStore(scope)">新增题库</el-button>
            </template>
            <template slot-scope="scope">
                <!-- 查看题库（操作权限2） -->
                <router-link v-if="$store.state.admin>=2"
                :to="'/Exam_index/Store_look?store_id='+scope.row.id">
                    <el-button 
                    type="success" plain style="margin-right: 10px;"
                        size="mini">查看
                    </el-button>
                </router-link>
                <el-button v-else
                    type="success" plain style="margin-right: 10px;"
                    size="mini"
                    disabled>查看
                </el-button>
                <!-- 编辑题库(操作权限2) -->
                <router-link 
                    :to="'/Exam_index/Store_edit?store_id='+scope.row.id+
                    '&store_name='+scope.row.store_name" 
                    v-if="$store.state.admin>=2"
                >
                    <el-button type="primary" plain style="margin-right: 10px;"
                        size="mini">编辑
                    </el-button>
                </router-link>
                <el-button type="primary" plain
                    size="mini"
                    disabled
                    v-else>编辑
                </el-button>
                <!-- 删除题库(操作权限3) -->
                <el-button
                    size="mini"
                    type="danger" plain
                    v-if="$store.state.admin>=3"
                    @click="Delete(scope.$index, scope.row)">删除
                </el-button>
                <el-button
                    size="mini"
                    type="danger" plain
                    v-else
                    disabled>删除
                </el-button>
            </template>
        </el-table-column>
        </el-table>
    </div>
        <!-- 分页 -->
        <div style="margin:2vh 0">
            <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[25, 50, 75, 100]"
            :page-size="50"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
    </div>
    <!-- 新增库存 -->
    <el-dialog
        title="新增库存"
        :visible.sync="addStoreVisible"
        width="50%"
        top=5vh
        :before-close="handleClose">
            <Store_add :getStoreInfo="getStoreInfo" :handleClose="handleClose"></Store_add>
    </el-dialog>
</div> 
</template>
        
<script>
import axios from 'axios'
import Store_add from './Store_add.vue'
export default {
    name:"MyStore",
    components:{Store_add}, 
    data() {
        return {
            currentPage: 1,       // 当前的页码
            page_size: 50,        // 每页显示条数
            store_list:[{},{}],   // 题库信息列表
            total:1,   // 题库列表总长度
            addStoreVisible:false // 新增库存弹出层
        }
    },
    methods: {
        // 新增题库
        addStore(){
            this.addStoreVisible = true
        },
        // 关闭弹出框
        handleClose() {
            this.addStoreVisible = false
        },
        // 删除题库
        Delete(index, row){
            console.log(index, row);
            this.$MessageBox.confirm('此操作将永久删除该题库, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 发送请求
                let params = {
                    dataSheet: 'exam_store_list',
                    id: row.id,
                }
                axios({
                    method:'get',
                    url:'https://www.xiaolily.cn/Delete.php',
                    params:params
                }).then(
                response => {
                    console.log('删除',response)
                    this.getStoreInfo()
                    // 删除成功提示
                    this.$message({
                    type: 'success',
                    message: response.data.msg
                    });
                },
                error => {
                    console.log('请求失败了',error.message)
                })
            })
        },
        // 改变每页显示数量事件
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.page_size = val
            this.getStoreInfo()
        },
        // 页码切换事件
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.getUserInfo()
        },
        // 分页获取题库表中数据
        getStoreInfo(){
            // 发送数据
            let params = {
            currentPage: this.currentPage,
            page_size: this.page_size,
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Exam_store_get.php',
                params:params
            }).then(
                response => {
                this.store_list = response.data.data
                this.total = parseInt(response.data.total)  // element分页必需接受整数类型
                // console.log('题库：',response)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
    },
    mounted(){
        this.getStoreInfo()
    }
}
</script>

<style scoped>
.table_section{
    width: 80%;
    margin-left: 10%;
    margin-top: 3vh;
}
</style>