<template>
<div class="flex_column">
  <div class="table_section">
    <!-- stripe属性创建带斑马纹的表格，border带边框,height='70vh'固定表头 -->
    <el-table
      border
      stripe 
      height='70vh'
      :data="data_list"
      :default-sort = "{prop: 'date', order: 'descending'}"
    >
    <div v-for="(column,index) in column_array" :key="index">
      <el-table-column
        :prop="column"
        :label="column"
        sortable
        width="120"
        align="center">
      </el-table-column>
    </div>
    </el-table>
    <!-- 分页 -->
    <div style="margin:2vh 0">
      <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="[25, 50, 75, 100]"
      :page-size="50"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
      </el-pagination>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    name:"UserAdmin",
    props:['table_name'],
    data() {
      return {
        currentPage: 1,       // 当前的页码
        page_size: 50,        // 每页显示条数
        data_list:[{},{}],    // 数据列表
        total:1,              // 列表总长度
        column_array:[],      // 列名列表
      }
    },
    watch: {  
      // 监听 table_name 的变化  
      table_name(newValue, oldValue) {  
        this.getInfo(newValue)
      }  
    }, 
    methods: {
      // 改变每页显示数量事件
      handleSizeChange(val) {
          console.log(`每页 ${val} 条`);
          this.page_size = val
          this.getInfo()
      },
      // 页码切换事件
      handleCurrentChange(val) {
          console.log(`当前页: ${val}`);
          this.getInfo()
      },
      // 分页获取数据表中数据
      getInfo(){
        // 发送数据
        let params = {
          currentPage: this.currentPage,
          page_size: this.page_size,
          table: this.table_name,
        }
        axios({
            method:'get',
            url:'https://www.xiaolily.cn/BigData_Data_get.php',
            params:params
        }).then(
            response => {
              this.data_list = response.data.data
              this.total = parseInt(response.data.total)     // 他们那个表格必需接受整数类型
              this.column_array = response.data.column_array
              // console.log(this.column_array)
            },
            error => {
                console.log('请求失败了',error.message)
            }
        )
      },
    },
    mounted(){
        this.getInfo()
    }
}
</script>

<style scoped>
.table_section{
  width: 100%;
  margin-top: 2vh;
}
</style>