<template>
  <body class="chinese_font" style="position: relative">
    <!-- 抽卡视频 -->
    <video
      v-if="isPlaying"
      ref="videoElement"
      @ended="onVideoEnded"
      autoplay
      muted
      style="
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      "
    >
      <source :src="draw_video" type="video/mp4" />
    </video>
    <!-- 标题 -->
    <div class="flex_row">
      <p style="font-size: 30px; line-height: 60px">坤神 启动！</p>
    </div>
    <!-- 纠缠之缘 -->
    <div class="flex_row" style="position: absolute; top: 20px; right: 20px">
      <span style="color: red; margin-right: 20px">限时特惠</span>
      <img
        style="width: 50px; margin-right: 10px"
        src="../../assets/image/jiuchan.png"
        alt=""
      />
      <span style="text-decoration: line-through; margin-right: 10px">160</span>
      <span>80</span>
      <img width="20px" src="../../assets/image/jifen.png" alt="" />
    </div>
    <!-- 卡池 -->
    <div class="flex_row">
      <!-- 本期限定 -->
      <div class="flex_column" style="margin-top: 20px; margin-right: 30px">
        <span>本期限定</span>
        <img
          width="100px"
          src="https://www.xiaolily.cn/img_avatar/8.gif"
          alt=""
        />
        <el-rate style="z-index: -1" :value="5" disabled></el-rate>
      </div>
      <div class="flex_column" style="margin-top: 20px; margin-right: 30px">
        <span>返场限定</span>
        <img
          width="100px"
          src="https://www.xiaolily.cn/img_avatar/7.gif"
          alt=""
        />
        <el-rate style="z-index: -1" :value="5" disabled></el-rate>
      </div>
      <!-- 常驻卡池 -->
      <div class="flex_column">
        <span>常驻卡池</span>
        <div class="flex_row">
          <div class="flex_column" style="margin-top: 20px; margin-right: 20px">
            <img
              style="width: 100px; height: 100px"
              src="https://www.xiaolily.cn/img_avatar/1.gif"
              alt=""
            />
            <el-rate style="z-index: -1" :value="5" disabled></el-rate>
          </div>
          <div class="flex_column" style="margin-top: 20px; margin-right: 20px">
            <img
              style="width: 100px; height: 100px"
              src="https://www.xiaolily.cn/img_avatar/2.gif"
              alt=""
            />
            <el-rate style="z-index: -1" :value="5" disabled></el-rate>
          </div>
          <div class="flex_column" style="margin-top: 20px; margin-right: 20px">
            <img
              style="width: 100px; height: 100px"
              src="https://www.xiaolily.cn/img_avatar/3.gif"
              alt=""
            />
            <el-rate style="z-index: -1" :value="5" disabled></el-rate>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex_column"
      style="width: 50%; margin-left: 25%; margin-top: 4vh"
    >
      <!-- 操作按钮 -->
      <div class="flex_row" style="width: 100%">
        <el-button @click="draw(1)" type="primary" plain>单抽</el-button>
        <el-button @click="draw(10)" type="warning" plain>十连</el-button>
      </div>
      <!-- 图片 -->
      <div
        class="flex_row"
        style="
          flex-wrap: wrap;
          width: 600px;
          justify-content: space-evenly;
          margin-top: 5vh;
        "
      >
        <div
          v-for="(img_src, index) in img_srcs"
          :key="index"
          style="margin: 0 10px"
        >
          <img
            v-if="img_src"
            style="border-radius: 10px; width: 100px; height: 100px"
            :src="'https://www.xiaolily.cn/' + img_src"
            alt=""
          />
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import axios from "axios";
export default {
  name: "ShopCard",
  data() {
    return {
      img_srcs: [], // 抽出的卡片
      isPlaying: false, // 控制视频是否播放和显示
      draw_video: "",
    };
  },
  methods: {
    toggleVideo() {
      this.isPlaying = true; // 显示视频并开始播放
      this.playVideo();
    },
    onVideoEnded() {
      this.isPlaying = false; // 视频播放结束，隐藏视频
    },
    playVideo() {
      const videoElement = this.$refs.videoElement;
      if (videoElement) {
        videoElement.play(); // 开始播放视频
      }
    },
    // 单抽
    draw(count) {
      let params = {
        count: count,
      };
      // 发送请求
      axios({
        method: "get",
        url: "https://www.xiaolily.cn/Random_img.php",
        params: params,
      }).then(
        (response) => {
          console.log(response);
          if (response.data.error == 0) {
            // 播放视频
            if (count == 1) {
              if (response.data.gold == 0) {
                this.draw_video = require("../../assets/image/blue1.mp4");
              } else {
                this.draw_video = require("../../assets/image/gold1.mp4");
              }
            } else {
              if (response.data.gold == 0) {
                this.draw_video = require("../../assets/image/blue10.mp4");
              } else {
                this.draw_video = require("../../assets/image/gold10.mp4");
              }
            }
            this.toggleVideo();
            // 显示抽奖结果
            setTimeout(() => {
              this.img_srcs = response.data.img;
              console.log(this.img_srcs);
            }, 2000);
            this.$message({
              type: "success",
              message: response.data.msg,
              duration: 500,
            });
          } else {
            this.$message({
              type: "warning",
              message: response.data.msg,
              duration: 1000,
            });
          }
        },
        (error) => {
          console.log("请求失败了", error.message);
        }
      );
    },
  },
  mounted() {},
};
</script>

<style scoped>
body {
  position: relative;
  width: 80vw;
  margin-left: 12vw;
  margin-top: 2vh;
  min-height: 80vh;
  background-color: rgba(255, 255, 255, 0.5);
}
.rule {
  font-size: large;
  margin: 5px 0;
}
img {
  border-radius: 8px;
}
</style>