<template>
<!-- 响应式布局 -->
<el-row :gutter="10">
    <!-- 左边留白 -->
    <el-col :xs="0" :sm="2" :md="3" :lg="4" :xl="4">
        <div style="min-height: 30px;"></div>
    </el-col>
    <!-- 作业区域 -->
    <el-col :xs="24" :sm="20" :md="18" :lg="16" :xl="16">
        <div class="task_section chinese_font">
        <!-- 班级选择 -->
        <el-form>
            <el-form-item label="选择班级">
                <el-select v-model="choosed_class" @change="getTaskList()"
                placeholder="请选择班级">
                    <el-option
                    v-for="(item,index) in class_list" :key="index"
                    :value="item.class_name">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <!-- 作业列表 -->
        <div class="task_box_list chinese-font">
            <div 
            @click="toDetail($event,task.id,task.title,task.class_name,task.publisher,task.AI_teacher_list,task.timu_require)"
            class="task_box" v-for="task in task_list" :key="task.id">
                <h3>{{task.title}}</h3>
                <div class="line"></div>
                <h4>{{task.class_name}}</h4>
                <h5>发布者:{{task.publisher}}</h5>
            </div>
        </div>
        <!-- 分页 -->
        <div style="margin:2vh 0">
            <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[12, 24, 36, 48]"
            :page-size="12"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
    </div>
    </el-col>
    <!-- 右边留白 -->
    <el-col :xs="0" :sm="2" :md="3" :lg="4" :xl="4">
        <div style="min-height: 30px;"></div>
    </el-col>
</el-row>

</template>

<script>
import axios from 'axios'
export default {
    name:'MyTask',
    data () {
        return {
            currentPage: 1,          // 当前的页码
            page_size: 12,           // 每页显示条数
            task_list:[],      
            total:1,                 // 用户列表总长度
            class_list: [],          //班级列表
            choosed_class: '所有班级',       //用户选中的班级
        }
    },
    methods: {
        // 编程式路由导航到作业详情页
        toDetail(event,id,title,class_name,publisher,AI_teacher_list,timu_require){
            this.$router.push({
                name:'Task_detail',
                query:{
                    id:id,
                    title:title,
                    class_name:class_name,
                    publisher:publisher,
                    AI_teacher_list:AI_teacher_list,
                    timu_require:timu_require,
                }
            })
        },
        // 分页获取数据
        getTaskList(){
            // 发送数据
            let params = {
                currentPage: this.currentPage,
                page_size: this.page_size,
                class_name : this.choosed_class  
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Task_list_get.php',
                params:params
            }).then(
                response => {
                    this.task_list = response.data.data
                    this.total = parseInt(response.data.total)
                    for (let i in this.task_list){
                        this.task_list[i].AI_teacher_list = JSON.parse(this.task_list[i].AI_teacher_list)
                    }
                    // console.log(this.task_list)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 改变每页显示数量事件
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.page_size = val
            this.getTaskList()
        },
        // 页码切换事件
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.getTaskList()
        },
        // 获取班级列表
        getClassList(){
            // 发送数据
            let params = {
                currentPage: 1,
                page_size: 100  
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Class_get.php',
                params:params
            }).then(
                response => {
                    this.class_list = response.data.data
                    this.class_list.unshift({'class_name':'所有班级'})
                    // console.log(this.class_list)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 根据自己的班级选择班级，管理员查看所有班级
        chooseClass(){
            if(this.$store.state.class_name){
                this.choosed_class = this.$store.state.class_name
            }
            if(this.$store.state.admin>=2){
                this.choosed_class = '所有班级'
            }
            this.getTaskList()
        },
    },
    mounted(){
        this.getTaskList()
        this.getClassList()
        // 如果执行得太快，缓存还没有获取，是拿不到数据的
        setTimeout(()=>{
            this.chooseClass()
        },2000);
    }
}
</script>

<style scope>
.task_section{
    margin-top: 5vh;
    border: 2px solid #94817e;
    padding: 20px;
    background-color: #e0e0e0;
    border-radius: 12px 8px 8px 12px;
}
.task_box_list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.task_box{
    border: #926f69 solid 2px;
    border-radius: 12px 8px 8px 12px;
    box-sizing: border-box;
    width: 25%;
    margin: 10px;
    height: 120px;
    background-color: rgba(255, 255, 255, 0.5);
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    transition: all 1s;
    padding: 20px;
}
.task_box:hover{
    flex-grow: 6;
}
.task_box::before {  
  content: "";  
  position: absolute;  
  top: 0;  
  left: 0;  
  right: 0;  
  bottom: 0;  
  background-image: url('https://www.xiaolily.cn/image/banner/dog/1.png');  
  background-size: contain;  
  background-position: right top;  
  transform: rotate(180deg);  
  z-index: -1; /* 确保伪元素在内容之上 */  
  background-repeat: no-repeat;
  transition: all 1s;
  transform: scale(3);
  background-origin: content-box;
  padding: 20px;
}
.task_box:nth-child(2)::before {  
  background-image: url('https://www.xiaolily.cn/image/banner/dog/2.png');  
}
.task_box:nth-child(3)::before {  
  background-image: url('https://www.xiaolily.cn/image/banner/dog/3.png');  
}
.task_box:nth-child(4)::before {  
  background-image: url('https://www.xiaolily.cn/image/banner/dog/4.png');  
}
.task_box:nth-child(5)::before {  
  background-image: url('https://www.xiaolily.cn/image/banner/dog/5.png');  
}
.task_box:nth-child(6)::before {  
  background-image: url('https://www.xiaolily.cn/image/banner/dog/6.png');  
}
.task_box:nth-child(7)::before {  
  background-image: url('https://www.xiaolily.cn/image/banner/dog/7.png');  
}
.task_box:nth-child(8)::before {  
  background-image: url('https://www.xiaolily.cn/image/banner/dog/8.png');  
}
.task_box:nth-child(9)::before {  
  background-image: url('https://www.xiaolily.cn/image/banner/dog/9.png');  
}
.task_box:nth-child(10)::before {  
  background-image: url('https://www.xiaolily.cn/image/banner/dog/10.png');  
}
.task_box:nth-child(11)::before {  
  background-image: url('https://www.xiaolily.cn/image/banner/dog/11.png');  
}
.task_box:nth-child(12)::before {  
  background-image: url('https://www.xiaolily.cn/image/banner/dog/12.png');  
}
.task_box:hover::before {  
  transform: rotate(0deg); 
  z-index: 1; 
}
.task_box h3{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: larger;
    color: rgb(112, 74, 74);
    transition: 1s all;
}
.task_box:hover h3{
    color: red;
}
.line{
    background-color: red;
    width: 0;
    height: 3px;
    margin: 5px 0;
    transition: 1s all;
}
.task_box:hover .line{
    width: 50%;
}
h4{
    font-size: medium;
    color: #94817e;
}
h5{
    font-size: small;
    font-weight: normal;
    color: gray;
    margin-top: 5px;
    transform: translate(-500px);
    transition: all 1s;
}
.task_box:hover h5{
    transform: translate(0px);
}
</style>