<template>
<div class="flex_column">
    <div class="table_section">
        <!-- stripe属性创建带斑马纹的表格，border带边框,height='70vh'固定表头 -->
        <el-table
            border
            stripe 
            height='70vh'
            :data="class_list"
            :default-sort = "{prop: 'date', order: 'descending'}"
        >
        <el-table-column
            prop="id"
            label="序号"
            sortable
            width="80"
            align="center">
        </el-table-column>
        <el-table-column
            prop="class_name"
            label="班级名"
            sortable
            width="200">
        </el-table-column>
        <el-table-column
            prop="class_num"
            label="班级码"
            sortable
            width="120"
            align="center">
        </el-table-column>
        <el-table-column
            prop="class_count"
            label="班级人数"
            sortable
            width="120"
            align="center">
        </el-table-column>
        <el-table-column
            prop="department"
            label="部门"
            sortable
            width="120"
            align="center">
        </el-table-column>
        <!-- 是否允许注册 -->
        <el-table-column
            label="是否允许注册"
            width="120"
            align="center">
            <template slot-scope="scope">
                <el-switch
                @change="changeIsSignup(scope.row)"
                v-model="scope.row.allowSignup"
                active-color="#13ce66"
                inactive-color="#dcdfe6">
                </el-switch>
            </template>
        </el-table-column>
        <el-table-column label="操作">
            <template slot="header">
                <el-button type="primary" plain 
                @click="addClassVisible=true">增加班级</el-button>
            </template>
            <template slot-scope="scope">
                <!-- 删除班级(操作权限3) -->
                <el-button
                size="mini"
                type="danger" plain
                v-if="$store.state.admin>=3"
                @click="Delete(scope.$index, scope.row)">删除
                </el-button>
                <el-button
                size="mini"
                type="danger" plain
                v-else
                disabled>删除
                </el-button>
            </template>
        </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="margin:2vh 0">
            <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[25, 50, 75, 100]"
            :page-size="50"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
    </div>
    <!-- 增加班级 -->
    <el-dialog
    title="增加班级"
    :visible.sync="addClassVisible"
    width="50%"
    top=5vh
    :before-close="handleClose">
        <Class_add :getClassInfo="getClassInfo" :handleClose="handleClose"></Class_add>
    </el-dialog>
</div>
</template>

<script>   
import axios from 'axios'
import Class_add from './Class_add.vue'
export default {
    name:"ClassAdmin",
    components: { Class_add },
    data() {
        return {
        currentPage: 1,          // 当前的页码
        page_size: 50,           // 每页显示条数
        class_list:[{},{}],      // 用户信息列表
        total:1,                 // 用户列表总长度
        addClassVisible: false,  // 增加班级弹出层
        }
    },
    methods: {
        // 关闭弹出框
        handleClose() {
            this.addClassVisible = false
        },
        // 删除班级
        Delete(index, row){
            console.log(index, row);
            this.$MessageBox.confirm('此操作将永久删除该班级,以及该班级的所有学生,是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 删除班级表中的班级
                let params = {
                    dataSheet: 'class',
                    id: row.id,
                }
                axios({
                    method:'get',
                    url:'https://www.xiaolily.cn/Delete.php',
                    params:params
                    }).then(
                    response => {
                        console.log('删除成功',response)
                        this.getClassInfo()
                        // 删除成功提示
                        this.$message({
                            type: 'success',
                            message: response.data.msg
                        });
                    },
                    error => {
                        console.log('请求失败了',error.message)
                    }
                )
                
            }).catch(() => {
                console.log('用户点击取消')
            });
        },
        // 改变是否允许注册
        changeIsSignup(row){
            console.log(row.id,row.allowSignup)
            let params = {
                id: row.id,
                allowSignup: row.allowSignup  
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Class_allowSignup_mod.php',
                params:params
            }).then(
                response => {
                    console.log(response)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 改变每页显示数量事件
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.page_size = val
            this.getClassInfo()
        },
        // 页码切换事件
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.getClassInfo()
        },
        // 分页获取class表中数据
        getClassInfo(){
            // 发送数据
            let params = {
                currentPage: this.currentPage,
                page_size: this.page_size  
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Class_get.php',
                params:params
            }).then(
                response => {
                    this.class_list = response.data.data
                    this.total = parseInt(response.data.total)  // element表格必需接受整数类型
                    // 把是否允许注册改为布尔类型,因为开关滑块需要一个布尔值
                    for(var i=0;i<this.class_list.length;i++){
                        if(this.class_list[i].allowSignup==1){
                            this.class_list[i].allowSignup = true
                        }else{
                            this.class_list[i].allowSignup = false
                        }
                    } 
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        }
    },
    mounted(){
        this.getClassInfo()
    }
}
</script>

<style scoped>
.table_section{
    width: 80%;
    margin-left: 5%;
    margin-top: 5vh;
}
</style>