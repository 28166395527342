<template>
<body>
  <div class="seciton">
    <h1>大数据训练隐藏入口</h1>
    <!-- 上传题目 -->
    <el-button v-if="$store.state.admin>=2"
    @click="dialogVisible = true"
    size="mini" type="success"
    >上传题目
    </el-button>
    <!-- 查看题目 -->
    <el-button
    @click="getTimu()"
    size="mini" type="primary"
    >查看题目
    </el-button>
    <!-- 面包屑导航 -->
    <div class="bread">
      <span :class="current_table==table?'bread_box_active':'bread_box'"
      v-for="(table,index) in table_list" :key="index"  
      @click="choose_table(table)">
        {{table}}
        <span style="color: gray;font-size: medium;"> / </span>
      </span>
    </div>
    <BigData :table_name="current_table"></BigData>
  </div>
  <!-- 上传题目弹出框 -->
  <el-dialog title="上传题目" :visible.sync="dialogVisible" width="30%">
    <div>
        <span>重新上传会覆盖之前的提交：</span>
        <el-input type="textarea" :rows="14" v-model="user_input"></el-input>
    </div>
    <div slot="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="upload">确定</el-button>
    </div>
  </el-dialog>
  <!-- 查看题目弹出框 -->
  <el-dialog title="查看题目" :visible.sync="look_dialogVisible" width="80%">
    <pre class="timu">{{timu_content}}</pre>
  </el-dialog>
</body>
</template>

<script>
import axios from 'axios'
import BigData from '../components/BigData'
export default {
  components:{BigData},
  data() {
    return {
      table_name:'player',
      table_list:'',             // 数据库中所有的表名
      current_table:'',          // 当前选中的数据表
      dialogVisible:false,       // 题目上传输入框
      look_dialogVisible:false,  // 查看题目弹出框
      user_input:'',             // 用户提交的内容  
      timu_content:'',           // 题目
    }
  },
  methods:{
    choose_table(table){
      this.current_table = table
    },
    // 获取数据库所有表名
    getTable(){
        axios({
              method:'get',
              url:'https://www.xiaolily.cn/BigData_table_get.php',
          }).then(
              response => {
                this.table_list = response.data.data
                this.table_list = this.table_list.reverse()
                this.current_table = this.table_list[0]  // 默认选中第一个表
                // console.log(this.table_list)
              },
              error => {
                  console.log('请求失败了',error.message)
              }
          )
    },
    // 获得题目
    getTimu(){
      this.look_dialogVisible = true
      let params = {
        table_name:this.current_table
      }
      axios({
          method:'get',
          url:'https://www.xiaolily.cn/BigData_timu_get.php',
          params:params
        }).then(
          response => {
            this.timu_content = response.data.content
          },
          error => {
              console.log('请求失败了',error.message)
          }
      )
    },
    // 上传题目
    upload(){
      let params = {
          table_name: this.current_table,
          content: this.user_input,
        }
        // 发送请求
        axios({
            method:'get',
            url:'https://www.xiaolily.cn/BigData_timu_add.php',
            params:params
        }).then(
            response => {
                // console.log(response)
                this.$message({
                    message: '上传成功',
                    type: 'success',
                    duration:1000
                })
                this.dialogVisible = false  // 关闭题目
            },
            error => {
                console.log('请求失败了',error.message)
            }
        )
    },
  },
  mounted(){
    this.getTable()
  } 
}
</script>

<style scoped>
  .timu{
    white-space: pre-wrap;
    font-size: 28px;
    line-height: 36px;
    font-weight: bold;
    -moz-user-select: all;
    -o-user-select: all;
    -khtml-user-select: all;
    -webkit-user-select: all;
    -ms-user-select: all;
    user-select: all;
  }
  .bread_box{
    color:gray
  }
  .bread_box_active{
    color:black;
    font-weight: bolder;
  }
  .bread{
    margin-top: 2vh;
    font-size: larger;
  }
  .seciton{
    margin-top: 10vh;
    width: 80vw;
    margin-left: 10vw;
  }
</style>