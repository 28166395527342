<template>
<div class="flex_column">
    <el-form label-position="left" label-width="80px">
        <!-- 试卷名称 -->
        <el-form-item label="试卷名称">
            <el-input v-model.trim="exam_name"
            placeholder="请输入试卷名称"></el-input>
        </el-form-item>
        <!-- 题目数量 -->
        <el-form-item label="题目数量">
            <el-input v-model.number="timu_count"
            placeholder="请输入题目数量"></el-input>
        </el-form-item>
        <!-- 课程选择 -->
        <div v-for="(i,index) in course_list" :key="index"></div>
        <el-form-item label="选择课程">
            <el-select v-model="choosed_course" @change="getStoreList"
            placeholder="请选择课程">
                <el-option
                v-for="(item,index) in course_list" :key="index"
                :value="item">
                </el-option>
            </el-select>
        </el-form-item>
        <!-- 题库选择 -->
        <div style="margin: 20px 0;">
            <el-checkbox-group v-model="choosed_store" v-for="store in store_list" :key="store.id">
                <el-checkbox :label="store.store_name"></el-checkbox>
            </el-checkbox-group>
        </div>
        <!-- 班级选择 -->
        <el-form-item label="选择班级">
            <el-select v-model="choosed_class" @change="getStoreList"
            placeholder="请选择班级">
                <el-option
                v-for="(item,index) in class_list" :key="index"
                :value="item.class_name">
                </el-option>
            </el-select>
        </el-form-item>
    </el-form>
    <!-- 提交按钮 -->
    <el-button type="primary" plain @click="submit">提交</el-button>
</div>
</template>
    
<script>
import axios from 'axios'

export default {
    name:'StoreAdd',
    props:['getExamList','handleClose'],
    data() {
        return { 
            exam_name: '',            
            timu_count:'',        
            course_list:[{},{}],      
            choosed_course:'',       //用户已经选中的课程
            store_list:[],           //所选中课程所对应的题库列表
            choosed_store: [],       //用户选中的题库
            class_list: [],          //班级列表
            choosed_class: [],       //用户选中的班级
        }
    },
    methods: {
        // 提交按钮
        submit(event){
            // 前端表单校验
            if(!this.exam_name){
                this.$message({
                    type:'warning',
                    message:'前端:试卷名没填'
                })
                event.preventDefault()
                return false
            }
            let numReg = /^[0-9]*$/
            var numRe = new RegExp(numReg)
            if(!numRe.test(this.timu_count)){
                this.$message({
                    type:'warning',
                    message:'前端:题目数只能是数字'
                })
                event.preventDefault()
                return false
            }
            // 发送请求
            let params = {
                exam_name: this.exam_name,
                timu_count: this.timu_count,
                choosed_course: this.choosed_course,
                choosed_class: this.choosed_class,
                choosed_store: JSON.stringify(this.choosed_store) 
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Exam_list_add.php',
                params:params
            }).then(
                response => {
                    console.log(response)
                    if(response.data.error == 0){
                        this.getExamList()  // 再次请求,更新新数据
                        this.handleClose()   // 关闭弹出层
                        this.$message({
                            type:'success',
                            message:response.data.msg
                        })
                    }else{
                        this.$message.error(response.data.msg)
                    }
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },      
        // 获取课程列表
        getCourseList(){
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Exam_course_get.php',
            }).then(
                response => {
                    console.log('获取课程名称:',response)
                    this.course_list = response.data.data
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        //选中课程列表后，获得该课程的所有题库
        getStoreList(){
            let params = {
                course_name: this.choosed_course,
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Exam_store_get_choose.php',
                params:params
            }).then(
                response => {
                    this.store_list = response.data.data
                    console.log(this.store_list)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 获取班级列表
        getClassList(){
            // 发送数据
            let params = {
                currentPage: 1,
                page_size: 100  
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Class_get.php',
                params:params
            }).then(
                response => {
                    this.class_list = response.data.data
                    // console.log(this.class_list)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        }
    },
    mounted(){
        this.getCourseList()
        this.getClassList()
    }
}
</script>

<style scoped>
.input_box{
    width: 50%;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
}
</style>