<template>
    <div class="section" >
        <!-- 标题栏 -->
        <div class="flex_row" style="justify-content: space-between;margin-bottom: 5vh;">
            <div class="chinese_font title">
                <i class="fa fa-bookmark-o"></i> 
                <span>有道翻译</span>
            </div>
            <a target="_blank" 
            href="https://www.xiaolily.cn:4000/2021/03/26/%E5%88%A9%E7%A5%9E%E7%BF%BB%E8%AF%91%E6%8E%A5%E5%8F%A3%E8%B0%83%E7%94%A8%E6%96%87%E6%A1%A3/" 
            >
                <el-button plain>查看接口调用文档</el-button>
            </a>
        </div>
        <div class="chinese_font red">接口失效了，有道那边肯定升级了防御机制，估计意识到偷接口的人太多了，我有空再去弄弄看</div>
        <!-- 翻译区 -->
        <div class="flex_row"  >
            <div style="width: 50%;" @keydown.enter="DictPost">
                <el-input
                style="width: 90%;margin-left: 5%;font-size: 20px;"
                type="textarea"
                :autosize="{ minRows: 10, maxRows: 12}"
                placeholder="请输入内容，按下回车确定"
                v-model.trim="content"
                maxlength="500"
                show-word-limit>
                </el-input>
            </div>
            <div style="width: 50%;">
                <el-input
                style="width: 90%;margin-left: 5%;font-size: 20px;"
                type="textarea"
                :autosize="{ minRows: 10, maxRows: 12}"
                v-model.trim="dictContent.todo1">
                </el-input>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name:'MyDict',
    data(){
        return{
            content:'',       // 用户输入的内容
            dictContent:'',   // 词典接口返回的内容
        }
    },
    methods:{
        onEnter(event) {
    event.preventDefault();
    console.log('按下了Enter键');
  },
        // Unicode转义(\uXXXX)的编码和解码
        decode(s) {
            return unescape(s.replace(/\\(u[0-9a-fA-F]{4})/gm, '%$1'));
        },
        // Ajax异步翻译接口
        DictPost(){
            if(this.content.length == 0){
                alert('内容不能为空')
                return
            }
            axios.get('https://www.xiaolily.cn/dictApi.php?content='+this.content).then(
                response => {
                    this.dictContent = this.decode(response.data.content)
                    this.dictContent = JSON.parse(this.dictContent)
                    // console.log(this.dictContent)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        }
    }
}
</script>

<style scoped>
.section{
    width: 60vw;
    margin-left: 20vw;
    margin-top: 15vh;
    background: white;
    padding: 20px;
}
.title{
    font-size: 32px;
}
</style>