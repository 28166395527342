<template>
<div class="flex_column">
    <el-form :label-position="labelPosition" label-width="80px">
        <!-- 班级名 -->
        <el-form-item label="班级名">
            <el-input v-model.trim="class_name"
            placeholder="简写即可,如19计1"></el-input>
        </el-form-item>
        <!-- 人数 -->
        <el-form-item label="班级人数">
            <el-input v-model.number="class_count"
            placeholder="建议多预留五个"></el-input>
        </el-form-item>
        <!-- 班级码 -->
        <el-form-item label="班级码">
            <el-input v-model.number="class_num" 
            placeholder="班级码用于学生注册，输入任意四位数字即可"></el-input>
        </el-form-item>
        <!-- 专业部选择 -->
        <el-form-item label="专业部">
            <el-radio-group v-model="department" size="medium">
                <el-radio border label="信息部"></el-radio>
                <el-radio border label="商贸部"></el-radio>
                <el-radio border label="财经部"></el-radio>
            </el-radio-group>
        </el-form-item>
    </el-form>
    <!-- 提交按钮 -->
    <el-button type="primary" plain @click="submit">提交</el-button>
</div>
</template>

<script>
import axios from 'axios'
export default {
    name:'ClassAdd',
    props:['getClassInfo','handleClose'],
    data() {
        return {
            labelPosition: 'right',      //对齐方式，还有left和top   
            class_name: '',              //班级名
            class_count:'',              //班级人数 
            class_num:'',                //班级码
            department:'信息部',          //专业部      
        }
    },
    methods: {
        // 提交按钮
        submit(event){
            // 前端表单校验
            if(!this.class_name){
                this.$message({
                    type:'warning',
                    message:'前端:班级名没填'
                })
                event.preventDefault()
                return false
            }
            if(!this.class_count){
                this.$message({
                    type:'warning',
                    message:'前端:班级人数没填'
                })
                event.preventDefault()
                return false
            }
            let classNumReg = /^[0-9]{4}$/
            if(!classNumReg.test(this.class_num)){
                this.$message({
                    type:'warning',
                    message:'前端:班级码只能输入4位数字'
                })
                event.preventDefault()
                return false
            }
            // 发送请求
            let params = {
                class_name: this.class_name,
                class_count: this.class_count,
                class_num: this.class_num,
                department: this.department
            }
            axios({
                method:'get',
                url:'https://www.xiaolily.cn/Class_add.php',
                params:params
            }).then(
                response => {
                    console.log(response)
                    if(response.data.error == 0){
                        this.getClassInfo()  // 再次请求,更新新数据
                        this.handleClose()   // 关闭弹出层
                        this.$message({
                            type:'success',
                            message:response.data.msg
                        })
                    }else{
                        this.$message.error(response.data.msg)
                    }
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        }
    }
}
</script>

<style scoped>
.input_box{
    width: 50%;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
}
</style>